import React from 'react';
import {Helmet} from 'react-helmet-async';
import Transition from '../../transition';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';
import FileCardData from '../../data/FileCardData';
import FileCardList from '../../components/file-card/FileCardList';

function ProductCatalogs() {

  const request = FileCardData.sort((a,b)=>a.product.localeCompare(b.product)).filter(
    item => {
      return (
        item
        .type
        .toLowerCase()
        === 'product catalog'
      );
    }
  );

  return (
    <div className="pbg-main">
      <Helmet>
        <title>Plejd Brand Guide | Product catalogs</title>
      </Helmet>
      <Header />
      <div className="pbg-main-wrapper">
        <PageHeader 
          icon={<path d="M78 19h-4v-8a2.002 2.002 0 0 0-2.352-1.969L66 10.04V2A1.998 1.998 0 0 0 63.264.141l-48 19-.022.01c-.063.026-.121.061-.181.094-.051.028-.106.049-.155.081l-.013.01c-.049.032-.09.073-.136.109-.051.041-.106.078-.153.123l-.014.014c-.038.037-.067.082-.102.122l-.019.022c-.038.046-.08.087-.114.136a.121.121 0 0 1-.01.014c-.035.051-.06.108-.09.162-.03.054-.064.104-.088.161l-.005.012c-.023.054-.036.112-.054.167-.021.062-.046.123-.06.187l-.003.017c-.01.047-.011.098-.018.146l-.009.072c-.005.05-.017.098-.018.149 0 .01.002.019.001.029.001.009-.001.015-.001.022v67c0 2.206 1.794 4 4 4h60c2.206 0 4-1.794 4-4V23c0-2.206-1.794-4-4-4zm-8 0H38.577L70 13.389V19zm-8-8.246-26.749 4.777L62 4.943v5.811zM18 88V23h60l.003 65H18zm48-48a2 2 0 0 1-2 2H32a2 2 0 0 1 0-4h32a2 2 0 0 1 2 2zM56 72a2 2 0 0 1-2 2H42a2 2 0 0 1 0-4h12a2 2 0 0 1 2 2zm6-24a2 2 0 0 1-2 2H36a2 2 0 0 1 0-4h24a2 2 0 0 1 2 2z"/>}
          title={'Product catalogs'}
          subtitle={null}
        />
        <div className="pbg-main-container">
          {request.length === 0
            ? <div className="pbg-file-card-list-empty"><span>No files found</span></div>
            : <FileCardList request={request} />
          }
        </div>
      </div>
      <Footer />
    </div>
  );

}
export default Transition(ProductCatalogs);