import React from 'react';
import FileCard from './FileCard';
import './FileCardList.scss';

function FileCardList({request}) {

  const list = request.map(item => <FileCard key={item.id} item={item} />);
  
  return (
    <div className="pbg-file-card-list">
      {list}
    </div>
  );

}
export default FileCardList;