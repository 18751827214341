import React from 'react';
import {Helmet} from 'react-helmet-async';
import Transition from '../transition';
import Header from '../components/header/Header';
import Hero from '../components/hero/Hero';

function Home() {
  return (
    <div className="pbg-main">
      <Helmet>
        <title>Plejd Brand Guide</title>
      </Helmet>
      <Header />
      <div className="pbg-main-wrapper">
        <Hero />
      </div>
    </div>
  );
}
export default Transition(Home);