import React from 'react';
import {Helmet} from 'react-helmet-async';
import Transition from '../../transition';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';
import FileCardData from '../../data/FileCardData';
import FileCardList from '../../components/file-card/FileCardList';

function ProductSheetsES() {

  const request = FileCardData.sort((a,b)=>a.product.localeCompare(b.product)).filter(
    item => {
      return (
        item
        .type
        .toLowerCase()
        === 'product sheet' &&
        item
        .language
        .toLowerCase()
        === 'spanish'
      );
    }
  );

  return (
    <div className="pbg-main">
      <Helmet>
        <title>Plejd Brand Guide | Product sheets - Spanish</title>
      </Helmet>
      <Header />
      <div className="pbg-main-wrapper">
        <PageHeader 
          icon={<path d="m85.414 18.586-14-14A2 2 0 0 0 70 4H20c-7.91 0-10 6.541-10 10v68c0 3.459 2.09 10 10 10h56c7.91 0 10-6.541 10-10V20a2 2 0 0 0-.586-1.414zM82 20.829V28H67c-4.696 0-4.989-4.176-5-5V8h7.172L82 20.829zM76 88H20c-5.646 0-5.989-5.007-6-6V14.007C14.012 12.995 14.362 8 20 8h38v15c0 3.618 2.396 9 9 9h15v49.989C81.988 82.993 81.646 88 76 88zM63 38H33c-3.3 0-6 2.7-6 6v30a6 6 0 0 0 6 6h30c3.3 0 6-2.7 6-6V44a6 6 0 0 0-6-6zm-11 4v4h-8v-4h8zM38 76v-4h20v4H38zm27-2c0 1.084-.916 2-2 2h-1v-4c0-2.2-1.8-4-4-4H38c-2.2 0-4 1.8-4 4v4h-1c-1.103 0-2-.897-2-2V44c0-1.084.916-2 2-2h7v4c0 2.2 1.8 4 4 4h8c2.2 0 4-1.8 4-4v-4h7c1.103 0 2 .897 2 2v30z"/>}
          title={'Product sheets'}
          subtitle={'Spanish'}
        />
        <div className="pbg-main-container">
          {request.length === 0
            ? <div className="pbg-file-card-list-empty"><span>No files found</span></div>
            : <FileCardList request={request} />
          }
        </div>
      </div>
      <Footer />
    </div>
  );

}
export default Transition(ProductSheetsES);