import React from 'react';
import {motion} from 'framer-motion';

function FileCard({item}) {

  const title = item.title;

  return(

    <div className="pbg-file-card-item">
      <motion.div
        initial={{opacity: 0.4, translateY: 20}}
        whileInView={{opacity: 1, translateY: 0}}
        viewport={{amount: 0.5, once: true}}
        transition={{
          type: "spring", stiffness: 100
        }}
      >
        <div className="pbg-file-card-item-content">
          <div className="pbg-file-card-left">
            <span className="pbg-file-card-type">{item.type}</span>
            <span className="pbg-file-card-title">
              <a href={item.file} target="_blank" rel="noreferrer">
                {title
                  ? <span>{item.title}</span>
                  : <span>{item.product}</span>
                }
              </a>
            </span>
            <span className="pbg-file-card-language">{item.language}</span>
            <span className="pbg-file-card-details">{item.details}</span>
          </div>
          <div className="pbg-file-card-right">
            <a className="pbg-file-card-link" href={item.file} target="_blank" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" height="32" width="32">
                <path d="M82 43.552V42c0-14.093-11.906-26-26-26-7.824 0-15.723 3.61-20.36 9.152C33.517 23.768 30.839 23 28 23c-9.122 0-16 6.878-16 16v.814C5.972 43.388 2 50.53 2 58c0 12.542 8.168 22 19 22h54c10.654 0 19-8.346 19-19 0-8.036-4.66-14.731-12-17.448zM75 76H21c-8.551 0-15-7.738-15-18 0-6.338 3.658-12.593 8.895-15.211A2 2 0 0 0 16 41v-2c0-6.841 5.159-12 12-12 2.651 0 5.052.88 6.586 2.414a1.998 1.998 0 0 0 3.078-.305C41.297 23.661 48.665 20 56 20c11.925 0 22 10.075 22 22v3a2 2 0 0 0 1.45 1.923C85.958 48.782 90 54.176 90 61c0 7.373-5.61 15-15 15zM60.292 52.474c.843.713.948 1.975.234 2.818l-11 13a2 2 0 0 1-3.052 0l-11-13a2 2 0 0 1 3.052-2.584L46 61.54V38a2 2 0 0 1 4 0v23.54l7.474-8.832a1.998 1.998 0 0 1 2.818-.234z"/>
              </svg>
            </a>
          </div>
        </div>
      </motion.div>
    </div>

  );
}
export default FileCard;