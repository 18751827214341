import React from 'react';
import {Helmet} from 'react-helmet-async';
import Transition from '../../transition';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';
import LanguageCardData from '../../data/LanguageCardData';
import LanguageCardList from '../../components/language-card/LanguageCardList';

function Doc() {

  const request = LanguageCardData.sort((a,b)=>a.language.localeCompare(b.language)).filter(
    item => {
      return (
        item
        .type
        .toLowerCase()
        === 'declaration of conformity'
      );
    }
  );

  return (
    <div className="pbg-main">
      <Helmet>
        <title>Plejd Brand Guide | Declaration of conformity</title>
      </Helmet>
      <Header />
      <div className="pbg-main-wrapper">
        <PageHeader 
          icon={<path d="M79.486 0H54.514C50.41 0 46.66 1.514 43.769 4H20c-5.514 0-10 4.486-10 10v68c0 5.514 4.486 10 10 10h56c5.514 0 10-4.486 10-10V42.656c5.875-2.533 10-8.377 10-15.17V16.514C96 7.408 88.592 0 79.486 0zM82 82c0 3.309-2.691 6-6 6H20c-3.309 0-6-2.691-6-6V14c0-3.309 2.691-6 6-6h20.391A16.387 16.387 0 0 0 38 16.514v10.972C38 36.592 45.408 44 54.514 44h.455l7.315 7.405a1.998 1.998 0 0 0 2.846 0L72.445 44h7.04c.857 0 1.692-.086 2.515-.212V82zm10-54.514C92 34.386 86.386 40 79.485 40h-7.876c-.535 0-1.047.214-1.423.595l-6.479 6.559-6.479-6.559A1.998 1.998 0 0 0 55.805 40h-1.291C47.614 40 42 34.386 42 27.486V16.514C42 9.614 47.614 4 54.514 4h24.973C86.387 4 92 9.614 92 16.514v10.972zM66.5 8C64.57 8 63 9.57 63 11.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5S68.43 8 66.5 8zm0 4a.5.5 0 1 1 .002-1.002A.5.5 0 0 1 66.5 12zM70 22.5v10c0 1.93-1.57 3.5-3.5 3.5S63 34.43 63 32.5v-10c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5zM24 18a2 2 0 0 1 2-2h6a2 2 0 0 1 0 4h-6a2 2 0 0 1-2-2zm0 12a2 2 0 0 1 2-2h6a2 2 0 0 1 0 4h-6a2 2 0 0 1-2-2zm48 48a2 2 0 0 1-2 2H26a2 2 0 0 1 0-4h44a2 2 0 0 1 2 2zm0-12a2 2 0 0 1-2 2H26a2 2 0 0 1 0-4h44a2 2 0 0 1 2 2zM24 54a2 2 0 0 1 2-2h22a2 2 0 0 1 0 4H26a2 2 0 0 1-2-2zm0-12a2 2 0 0 1 2-2h11a2 2 0 0 1 0 4H26a2 2 0 0 1-2-2z"/>}
          title={'Declaration of conformity'}
          subtitle={null}
        />
        <div className="pbg-main-container">
          {request.length === 0
            ? <div className="pbg-language-card-list-empty"><span>No files found</span></div>
            : <LanguageCardList request={request} />
          }
        </div>
      </div>
      <Footer />
    </div>
  );

}
export default Transition(Doc);