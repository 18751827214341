import React from 'react';
import {Helmet} from 'react-helmet-async';
import Transition from '../transition';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import PageHeader from '../components/page-header/PageHeader';

function PageNotFound() {

  return (
    <div className="pbg-main">
      <Helmet>
        <title>Plejd Brand Guide | Page not found</title>
      </Helmet>
      <Header />
      <div className="pbg-main-wrapper">
        <PageHeader 
          icon={<path d="M48 2C22.595 2 2 22.595 2 48s20.595 46 46 46 46-20.595 46-46S73.405 2 48 2zm0 88C24.841 90 6 71.159 6 48S24.841 6 48 6s42 18.841 42 42-18.841 42-42 42zm0-74c-3.859 0-7 3.14-7 7s3.141 7 7 7 7-3.14 7-7-3.141-7-7-7zm0 10c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0 10c-3.859 0-7 3.141-7 7v29c0 3.859 3.141 7 7 7s7-3.141 7-7V43c0-3.859-3.141-7-7-7zm3 36c0 1.654-1.346 3-3 3s-3-1.346-3-3V43c0-1.654 1.346-3 3-3s3 1.346 3 3v29z"/>}
          title={'Page not found'}
          subtitle={null}
        />
        <div className="pbg-main-container">
          <span className="pbg-not-found-text">The page you were looking for could not be found.</span>
        </div>
      </div>
      <Footer />
    </div>
  );

}
export default Transition(PageNotFound);