import React from 'react';
import LanguageCard from './LanguageCard';
import './LanguageCardList.scss';

function LanguageCardList({request}) {
  const list = request.map(item => <LanguageCard key={item.id} item={item} />);
  return (
    <div className="pbg-language-card-list">
      {list}
    </div>
  );
}
export default LanguageCardList;