import React, {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import Transition from '../../transition';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import '../../components/page-header/PageHeader.scss';
import '../../style/Search.scss';
import FileCardData from '../../data/FileCardData';
import FileCard from '../../components/file-card/FileCard';
import Fuse from 'fuse.js';
import {motion} from 'framer-motion';

function Search() {

  const [searchResults, setSearchResults] = useState(FileCardData);
  const options = {
    includeScore: true,
    threshold: 0.6,
    ignoreLocation: true,
    findAllMatches: true,
    ignoreFieldNorm: true,
    keys: ['type', 'product', 'title', 'language']
  }
  const fuse = new Fuse(FileCardData, options);
  const handleSearch = (e) => {
    const {value} = e.target;
    if (value.length === 0) {
      setSearchResults(FileCardData);
      return;
    }
    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setSearchResults(items);
  };

  return (
    <div className="pbg-main">
      <Helmet>
        <title>Plejd Brand Guide | Search</title>
      </Helmet>
      <Header />
      <div className="pbg-main-wrapper">
        <div className="pbg-page-header">
          <div className="pbg-hero-background">
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" viewBox="10 10 80 80">
              <path className="pbg-hero-background-element-1" d="M37-5C25.1-14.7,5.7-19.1-9.2-10-28.5,1.8-32.7,31.1-19.8,49c15.5,21.5,52.6,22,67.2,2.3C59.4,35,53.7,8.5,37-5Z"/>
              <path className="pbg-hero-background-element-2" d="M105.9,48.6c-12.4-8.2-29.3-4.8-39.4.8-23.4,12.8-37.7,51.9-19.1,74.1s63.9,15.3,76-5.6c7.6-13.3,1.8-31.1-2.3-43.8C117.6,63.3,114.7,54.3,105.9,48.6Z"/>
            </svg>
          </div>
          <div className="pbg-page-header-container">
            <motion.div
              initial={{opacity: 0, translateX: 100}}
              whileInView={{opacity: 1, translateX: 0}}
              viewport={{once: true}}
              transition={{
                type: "spring", stiffness: 50
              }}
            >
              <div className="pbg-page-header-title">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" height="32" width="32">
                  <path d="M91.1 86.9L65.8 61.5C70.9 55.4 74 47.6 74 39 74 19.7 58.3 4 39 4S4 19.7 4 39s15.7 35 35 35c8.6 0 16.4-3.1 22.5-8.2l25.3 25.3c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9c1.3-1.1 1.3-3.1.1-4.2zM39 68c-16 0-29-13-29-29s13-29 29-29 29 13 29 29-13 29-29 29z"/>
                </svg>
                <div className="pbg-search">
                  <input className="pbg-search-field" type="search" placeholder="Search" aria-label="Search" autoComplete="off" onChange={handleSearch} />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
        <div className="pbg-main-container">
          {searchResults.length === 0
            ? <div className="pbg-file-card-list-empty"><span>No files found</span></div>
            : ""
          }
          <div className="pbg-file-card-list">
            {searchResults.map((item, index) => (
            <FileCard key={index} item={item} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

}
export default Transition(Search);