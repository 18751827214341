import React, {useContext} from 'react';
import './Hero.scss';
import {motion} from 'framer-motion';
import {GlobalContext} from '../../global';

export default function Hero() {

  const {setMenuState} = useContext(GlobalContext);
  const menuToggle = event => {
    setMenuState(true);
  };

  return (
    <section className="pbg-hero">
      <div className="pbg-hero-background">
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" viewBox="10 10 80 80">
          <path className="pbg-hero-background-element-1" d="M37-5C25.1-14.7,5.7-19.1-9.2-10-28.5,1.8-32.7,31.1-19.8,49c15.5,21.5,52.6,22,67.2,2.3C59.4,35,53.7,8.5,37-5Z"/>
          <path className="pbg-hero-background-element-2" d="M105.9,48.6c-12.4-8.2-29.3-4.8-39.4.8-23.4,12.8-37.7,51.9-19.1,74.1s63.9,15.3,76-5.6c7.6-13.3,1.8-31.1-2.3-43.8C117.6,63.3,114.7,54.3,105.9,48.6Z"/>
        </svg>
      </div>
      <div className="pbg-hero-content">
        <motion.div
          initial={{opacity: 0, translateY: 100}}
          whileInView={{opacity: 1, translateY: 0}}
          viewport={{once: true}}
          transition={{
            type: "spring", stiffness: 50
          }}
        >
          <h1 className="pbg-hero-heading">Brand <br/>Guide</h1>
          <div className='pbg-hero-buttons'>
            <button type="button" aria-label="Start browsing" onClick={menuToggle}>Start browsing</button>
          </div>
        </motion.div>
      </div>
    </section>
  );
  
}