import React from 'react';
import {Helmet} from 'react-helmet-async';
import Transition from '../../transition';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';
import FileCardData from '../../data/FileCardData';
import FileCardList from '../../components/file-card/FileCardList';

function PressImages() {

  const request = FileCardData.filter(
    item => {
      return (
        item
        .type
        .toLowerCase()
        === 'press image'
      );
    }
  );

  return (
    <div className="pbg-main">
      <Helmet>
        <title>Plejd Brand Guide | Press images</title>
      </Helmet>
      <Header />
      <div className="pbg-main-wrapper">
        <PageHeader 
          icon={<path d="M88 16H8a4 4 0 0 0-4 4v56a4 4 0 0 0 4 4h80a4 4 0 0 0 4-4V20a4 4 0 0 0-4-4zm0 60H8V20h80v56zm-71.414-4.586a2 2 0 0 1 0-2.828l22-22a2 2 0 0 1 2.828 0L48 53.172l14.586-14.586a2 2 0 0 1 2.828 0l14 14a2 2 0 1 1-2.828 2.828L64 42.828 50.828 56l8.586 8.586a2 2 0 1 1-2.828 2.828L40 50.828 19.414 71.414c-.39.391-.902.586-1.414.586s-1.024-.195-1.414-.586zM26 44c5.514 0 10-4.486 10-10s-4.486-10-10-10-10 4.486-10 10 4.486 10 10 10zm0-16c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"/>}
          title={'Press images'}
          subtitle={null}
        />
        <div className="pbg-main-container">
          {request.length === 0
            ? <div className="pbg-file-card-list-empty"><span>No files found</span></div>
            : <FileCardList request={request} />
          }
        </div>
      </div>
      <Footer />
    </div>
  );

}
export default Transition(PressImages);