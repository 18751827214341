import React from 'react';
import {Helmet} from 'react-helmet-async';
import Transition from '../../transition';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import PageHeader from '../../components/page-header/PageHeader';
import FileCardData from '../../data/FileCardData';
import FileCardList from '../../components/file-card/FileCardList';

function ProductImagesCTR01() {

  const request = FileCardData.sort((a,b)=>a.product.localeCompare(b.product)).filter(
    item => {
      return (
        item
        .type
        .toLowerCase()
        === 'product image' &&
        item
        .product
        .toLowerCase()
        === 'ctr-01'
      );
    }
  );

  return (
    <div className="pbg-main">
      <Helmet>
        <title>Plejd Brand Guide | Product images - CTR-01</title>
      </Helmet>
      <Header />
      <div className="pbg-main-wrapper">
        <PageHeader 
          icon={<path d="M86 4H28c-3.309 0-6 2.691-6 6v11.898L6.475 26.35c-1.541.442-2.817 1.457-3.594 2.859s-.961 3.022-.519 4.563L18.35 89.525a5.96 5.96 0 0 0 2.859 3.593 5.96 5.96 0 0 0 4.563.521L81.524 77.65a5.96 5.96 0 0 0 3.595-2.858c.142-.256.258-.522.36-.791H86c3.309 0 6-2.691 6-6V10c0-3.309-2.691-6-6-6zM24.668 89.793a1.976 1.976 0 0 1-1.521-.173 1.989 1.989 0 0 1-.953-1.198L6.207 32.668a1.988 1.988 0 0 1 .173-1.521 1.987 1.987 0 0 1 1.197-.953L22 26.059V68c0 3.309 2.691 6 6 6h51.736L24.668 89.793zM88 68c0 1.103-.897 2-2 2H28c-1.103 0-2-.897-2-2V10c0-1.103.897-2 2-2h58c1.103 0 2 .897 2 2v58zM77 24h-9c0-3-2-4-5-4H51c-3 0-5 1-5 4h-9c-3 0-4 1-4 4v26c0 3 1 4 4 4h40c3 0 4-1 4-4V28c0-3-1-4-4-4zm0 30H37V28h13v-3.929A5.936 5.936 0 0 1 51 24h12c.464 0 .786.034 1 .071V28h13v26zM57 32c-4.971 0-9 4.029-9 9s4.029 9 9 9 9-4.029 9-9-4.029-9-9-9zm0 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5zm12-12.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z"/>}
          title={'Product images'}
          subtitle={'CTR-01'}
        />
        <div className="pbg-main-container">
          {request.length === 0
            ? <div className="pbg-file-card-list-empty"><span>No files found</span></div>
            : <FileCardList request={request} />
          }
        </div>
      </div>
      <Footer />
    </div>
  );

}
export default Transition(ProductImagesCTR01);